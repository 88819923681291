import { createApp } from 'vue'
import {router} from './router'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown';

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'

window.axios = require('axios');

const app = createApp(App)
        .use(PrimeVue)
        .use(router)
        .component('Dialog', Dialog)
        .component('Dropdown', Dropdown)
        .mount('#app')


import './less/atlas.less'
